body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  box-sizing: border-box;
}

.Matrix {
  /* background-color: #010101; */
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9;
  background: #000;
}

.MessageWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.Message {
  color: white;
  font-family: "Courier New", Courier, monospace !important;
  width: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
  padding: 10px;
  box-sizing: border-box;
  text-align: center;
  flex-wrap: wrap;
}

.Message span {
  font-family: "Courier New", Courier, monospace !important;
}

.code {
  position: fixed;
  z-index: 1;
}

.symbol {
  font-size: 24px;
  color: #009a22;
  text-shadow: 0px 0px 1px #36ba01;
}

.symbol.primary {
  color: #8bff8b;
  text-shadow: 0px 0px 10px white;
}
